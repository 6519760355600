export default {
	namespaced: true,
	state: {
		dashboardsData: {},
	},
	getters: {
		gettersDashboardsData: state => state.dashboardsData,
	},
	mutations: {
		set(state, obj) {
			state.dashboardsData[obj.type] = obj.data
		},
	},
	actions: {
		getPortalProjectsCount({ rootState, commit }) {
			return this.api.get(`${rootState.api_analytics_url_portal}GetPortalProjectsCount`)
			.then(res => {
				if(res.status == 200) {
					commit('set', { data: res.data, type: 'projectsCount' });
					return res
				}
			})
		},
		getProjectDiscussionSummary({ rootState, commit }) {
			return this.api.get(`${rootState.api_analytics_url_portal}GetProjectDiscussionSummary`)
			.then(res => {
				if(res.status == 200) {
					commit('set', { data: res.data, type: 'discussionSummary' });
					return res;
				}
			})
		},
		getAuthorCommentsIndicators({rootState, commit}) {
			return this.api.get(`${rootState.api_analytics_url_portal}GetAuthorCommentsIndicators`)
			.then(res => {
				if(res.status == 200) {
					commit('set', {data: res.data, type: 'authorComments'})
					return res
				}
			})
		},
	},
};
